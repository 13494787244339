export function showLinks() {
    var toggleMoreLinks = document.querySelectorAll('#toggle-more');

    toggleMoreLinks.forEach(function (toggleMoreLink) {
        toggleMoreLink.addEventListener('click', function (event) {
            event.preventDefault();

            let parentListItem = toggleMoreLink.parentNode;
            let menuItems = parentListItem.parentNode.querySelectorAll(
                '.dropdown-menu__item'
            );

            if (toggleMoreLink.getAttribute('data-state') === 'more') {
                menuItems.forEach(function (item, index) {
                    if (index >= 4 && item.style.display === 'none') {
                        setTimeout(function () {
                            item.style.display = 'block';
                        }, (index - 4 + 1) * 30);
                    }
                });

                toggleMoreLink.setAttribute('data-state', 'less');
                toggleMoreLink.textContent = 'Zwiń';
                let iconSpan = document.createElement('span');
                iconSpan.className =
                    'dropdown-link__icon dropdown-link__icon--active icon-chevron-left';
                toggleMoreLink.appendChild(iconSpan);
            } else {
                menuItems.forEach(function (item, index) {
                    if (index >= 4 && item.style.display === 'block') {
                        setTimeout(function () {
                            item.style.display = 'none';
                        }, (index - 4 + 1) * 30);
                    }
                });

                toggleMoreLink.setAttribute('data-state', 'more');
                toggleMoreLink.textContent = 'Zobacz więcej';
                let iconSpan = document.createElement('span');
                iconSpan.className = 'dropdown-link__icon icon-chevron-left';
                toggleMoreLink.appendChild(iconSpan);
            }
        });
    });
}
