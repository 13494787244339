export default function cutTextWployalty() {
    var elements = document.querySelectorAll(
        '.wlr-discount-point.wlr-text-color'
    );

    elements.forEach(function (element) {
        console.log(element);
        if (element.textContent.trim() === '100% nagroda') {
            element.textContent = '';
        }
    });
}
