import { showHeaderSticky } from './../scripts/header-sticky';
/**
 * menu dropdown
 */
//events
const dropdownOpen = new Event('dropdownOpen');
const dropdownClose = new Event('dropdownClose');
//helpers classes
const activeClass = 'is-active';
const dropdownClass = 'js-dropdown';
//DOM classes
const triggerClass = 'js-dropdown-trigger';
const hoverClassItem = document.querySelectorAll('.js-dropdown-trigger');
const triggerExpandMenu = 'js-dropdown-expand-menu';
// const toggleAll = 'js-dropdown-toggle-all'
//DOM objects
// const mainTrigger = document.getElementById('js-dropdown-main-trigger');

/**
 * triggers
 */
document.querySelectorAll('.' + triggerExpandMenu).forEach(item => {
    item.addEventListener(
        'click',
        event => {
            if ($(window).width() > 1180) return;
            expandMenu(item);
        },
        false
    );
});

document.querySelectorAll('.' + triggerClass).forEach(item => {
    item.addEventListener(
        'click',
        event => {
            toggleTarget(item, event);
        },
        false
    );

    // SHOW TARGET ON HOVER AND HIDE WHEN MOUSE LEAVE TARGET OD DESKTOP
    if ($(window).width() > 1180) {
        let targetAttribute = item.getAttribute('data-target');
        if (
            !item.classList.contains('dropdown-nav-trigger') &&
            targetAttribute != 'js-dropdown-search'
        ) {
            item.addEventListener(
                'mouseover',
                event => {
                    // TODO
                    clearAllActive();
                    showTarget(item, event);
                    $('.dropdown-search__container').removeClass('is-active');
                    $('.js-search-clear').removeClass('is-active');
                    $('#js-searcher-results').removeClass('is-active');
                    $('.js-input-search').blur();
                },
                false
            );
            if (targetAttribute) {
                let target = document.getElementById(targetAttribute);
                if (target) {
                    target.addEventListener(
                        'mouseleave',
                        event => {
                            hideTarget(item, event);
                        },
                        false
                    );
                }
            }
        }
    }
});

/**
 * open event
 */
document.addEventListener('dropdownOpen', event => {
    $('body').attr('aria-scrollable', true);
    // $('html').css({
    // overflow: 'hidden',
    // 'paddingRight' : (window.innerWidth - document.body.clientWidth),
    // });
});

/**
 * close event
 */
document.addEventListener('dropdownClose', event => {
    // removeActive([mainTrigger]);
    $('body').attr('aria-scrollable', false);
    // $('html').removeAttr('style');
});

/**
 * toggle active class
 * @param {object}
 */
function toggleTarget(elem, event) {
    let targetId = elem.dataset.target;
    if (targetId) {
        let target = document.getElementById(targetId);
        if (target) {
            // event.preventDefault();
            toggleActive([target]);
        }
    }
}

function expandMenu(item) {
    let targetId = item.dataset.target;
    if (targetId) {
        let target = document.getElementById(targetId);
        if (target) {
            if (item.classList.contains(activeClass)) {
                item.classList.remove(activeClass);
                target.classList.remove(activeClass);
            } else {
                collapseAllExpandMenu();

                item.classList.add(activeClass);
                target.classList.add(activeClass);
            }
        }
    }
}

function showTarget(elem, event) {
    let targetId = elem.dataset.target;
    if (targetId) {
        let target = document.getElementById(targetId);
        if (target) {
            event.preventDefault();
            addActive([target, elem]);
        }
    }
}

function clearAllActive() {
    document.querySelectorAll('.' + dropdownClass).forEach(item => {
        item.classList.remove(activeClass);
    });
    document.querySelectorAll('.' + triggerClass).forEach(item => {
        item.classList.remove(activeClass);
    });
}

function collapseAllExpandMenu() {
    document.querySelectorAll('.' + triggerExpandMenu).forEach(item => {
        let targetId = item.dataset.target;
        let target = document.getElementById(targetId);
        item.classList.remove(activeClass);
        target.classList.remove(activeClass);
    });
}

function hideTarget(elem, event) {
    let targetId = elem.dataset.target;
    if (targetId) {
        let target = document.getElementById(targetId);
        if (target) {
            event.preventDefault();
            removeActive([target, elem]);
        }
    }
}

/**
 * toggle active class
 * @param {object} items
 */
function toggleActive(items) {
    items.forEach(item => {
        if (item.classList.contains(activeClass)) {
            // if ($(window).width() > 1180) {
            document.dispatchEvent(dropdownClose);
            // }
            item.classList.remove(activeClass);
        } else {
            clearActive([dropdownClass]);
            document.dispatchEvent(dropdownOpen);
            item.classList.add(activeClass);
        }
    });

    showHeaderSticky();
}

/**
 * add active class
 * @param {object} items
 */
function addActive(items) {
    items.forEach(item => {
        item.classList.add(activeClass);
    });
}

/**
 * remove all active class
 * @param {object} items
 */
function removeActive(items) {
    items.forEach(item => {
        item.classList.remove(activeClass);
    });
}

/**
 * remove all active class from dropdowns
 * @param {array} classes
 */
function clearActive(classes) {
    classes.forEach(item => {
        document.querySelectorAll('.' + item).forEach(elem => {
            removeActive([elem]);
        });
    });
}

function checkActive(classes) {
    var active = false;
    classes.forEach(item => {
        document.querySelectorAll('.' + item).forEach(elem => {
            if (elem.classList.contains(activeClass)) {
                active = true;
            }
        });
    });
    return active;
}

const searchInput = 'js-input-search';
const searchTrigger = 'js-search-trigger';
$('body').on('click', '.' + searchTrigger, function () {
    $('.' + searchInput).focus();
    dropdown - search;
    $('.dropdown-search').addClass('is-active');
    $('#js-searcher-results').addClass('is-active');
});

$('.js-search-trigger').on('click', function () {
    $('#js-searcher-results').addClass('is-active');
    $('.js-search-clear').addClass('is-active');
});

$('.js-input-search').on('click', function () {
    $('.dropdown-search__container').addClass('is-active');
    $('.js-search-clear').addClass('is-active');
    $('#js-searcher-results').addClass('is-active');
    $('.dropdown.js-dropdown').removeClass('is-active');
});
