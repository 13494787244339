export default function popupGlowup() {
    setTimeout(function () {
        let popup = document.querySelector('.js-popup-glowup');
        if (popup) {
            popup.classList.remove('is-inactive');
        }
    }, 1000);

    let closePopupButton = document.querySelector('.js-close-popup-glowup');
    if (closePopupButton) {
        closePopupButton.addEventListener('click', function () {
            document.cookie =
                'popup_shown=true; path=/; max-age=' + 60 * 60 * 24 * 30;
            var popup = document.querySelector('.js-popup-glowup');
            if (popup) {
                popup.classList.add('is-inactive');
            }
        });
    }

    let seeMoreButton = document.querySelector('.js-see-more');
    if (seeMoreButton) {
        seeMoreButton.addEventListener('click', function () {
            document.cookie =
                'popup_shown=true; path=/; max-age=' + 60 * 60 * 24 * 30;
            var popup = document.querySelector('.js-popup-glowup');
            if (popup) {
                popup.classList.add('is-inactive');
            }
        });
    }
}
