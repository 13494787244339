// DHL
export function dhl() {
    jQuery(document.body).on('change', 'input.shipping_method', function () {
        setTimeout(() => {
            var selectedValue = jQuery(this).val();

            if (selectedValue.includes('dhl24_service_lm')) {
                console.log('wł');
                jQuery('#dhl24-shipping-sap').css('display', 'block');
            } else {
                console.log('wył');
                jQuery('#dhl24-shipping-sap').css('display', 'none');
            }
        }, 3200);
    });
}
