export default function favourites() {
    const fav = '.js-add-to-fav';
    const fav_count = '.js-fav-count';

    //fav single
    $(document).on('click', fav, function (e) {
        e.preventDefault();

        let $this = $(this),
            product_id = $this.attr('data-product_id');

        $.ajax({
            url: ajax.url,
            data: {
                action: 'add_to_fav',
                product_id: product_id,
            },
            type: 'post',
            beforeSend: function () {
                $this.parents('.js-product-box').block({
                    message: null,
                    overlayCSS: {
                        background: '#fff',
                        opacity: 0.6,
                    },
                });
                $(fav_count).addClass('is-load');
                $this.addClass('is-load');
            },
            success: function (response) {
                if (response.action == 'added') {
                    $this.addClass('is-added');
                } else if (response.action == 'remove') {
                    $this.removeClass('is-added');
                }
                $(fav_count).removeClass('is-load').html(`(${response.count})`);
                $this.parents('.js-product-box').unblock();
            },
        });
    });
}
