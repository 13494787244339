// Action alert scripts
export function actionAlert() {
    const alertItem = '.js-action-alert';
    const alertClose = '.js-action-alert-close';

    // Init all displayed alerts
    initAllAlerts();

    // Set events
    $('body').on('click', alertClose, function (e) {
        const $alert = $(this).closest(alertItem);
        $alert[0].hideAlert();
    });

    $('body').on('mouseover', alertItem, function (e) {
        const $alert = $(this).closest(alertItem);
        $alert[0].clearTimer();
    });

    $('body').on('mouseleave', alertItem, function (e) {
        const $alert = $(this).closest(alertItem);
        $alert[0].setTimer();
    });

    // Async alerts handle
    $('body').on('alert:show', function (e) {
        const uniqueId = Math.random().toString(36).substring(2);
        const alertHtml = _getAlertHtml(uniqueId, e);

        // Create and show alert
        $(alertHtml).appendTo('body');

        initAllAlerts();
    });
}

// Init alerts on all items with js-action-alert class
export function initAllAlerts() {
    $('.js-action-alert:not(.-displayed)').each(function (index) {
        _initAlert($(this), index);
    });
}

// Alert initialize
function _initAlert($alert, index = 1) {
    $alert[0].hideAlert = () => {
        _hideAlert($alert);
    };

    $alert[0].showAlert = () => {
        _showAlert($alert);
    };

    $alert[0].clearTimer = () => {
        _clearTimer($alert);
    };

    $alert[0].setTimer = () => {
        const time = index * 4000;
        _setTimer($alert, time);
    };

    _clearTimer($alert);
    _showAlert($alert);
}

function _getAlertHtml(id, alertData) {
    const { title, text, success, error } = alertData;
    return `
        <div class="action-alert js-action-alert ${
            success ? '-success' : error ? '-error' : ''
        }" id="alert-${id}">
            <div class="action-alert__content">
                <div>
                    <div class="action-alert__title">${title}</div>
                    <div class="action-alert__text">${text ? text : ''}</div>
                </div>
                <button class="action-alert__close js-action-alert-close" data-alert-id="${id}"></button>
            </div>
        </div>
    `;
}

function _hideAlert($alert) {
    $alert.removeClass('-active');
    $alert.addClass('-displayed');
}

function _showAlert($alert) {
    $alert.addClass('-active');
    _setTimer($alert);
}

function _setTimer($alert, time = 4000) {
    $alert.addClass('-counting');
    $alert[0].timer = setTimeout(function () {
        _hideAlert($alert);
    }, time);
}

function _clearTimer($alert) {
    $alert.removeClass('-counting');
    clearTimeout($alert[0].timer);
}
